
import {ref} from 'vue';
import {mdiChevronRight} from '@mdi/js';

import { registerIcons } from '@/Icons';

import contact from "@/components/contact.vue";
import imprint from "@/components/Imprint.vue";
import popup from "@/components/popup.vue";

registerIcons({mdiChevronRight});

export default {
	components: {
		contact,
		imprint,
		popup,
	},

	setup() {
		return {
			popImprint: ref(false),
			popContact: ref(false),
		}
	}
}
