
import {mdiClose} from '@mdi/js';
import {registerIcons} from '@/Icons';

registerIcons({mdiClose});

export default {
	name: "Popup",
	props: {
		direct: {
			type: Boolean,
			default: false,
		}
	}
};
