<template>
	<div class="contact">
		Marvin Wiesner<br>
		Friedrichstraße 6b<br>
		47475 Kamp-Lintfort<br>
		<a href="mailto:info@curv-labs.de">info@curv-labs.de</a>
	</div>
</template>

<script lang="ts">
export default {};
</script>

<style lang="scss">
.contact {
	padding: 32px;
	display: grid;
	// grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-gap: 32px;


	@media screen and (max-width: 1600px) {
		font-size: 20px;
	}

	&__textfield--mail,
	&__textarea {
		grid-column: 1 / -1;
	}
}
</style>
