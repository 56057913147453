<template>
	<home />
</template>

<script lang="ts">
import Home from "@/views/Home.vue";

export default {
	components: {
		Home,
	},
}
</script>

<style lang="scss">
@import '../node_modules/normalize.css/normalize.css';
@import url('/fonts/roboto/roboto.css');
@import url('/fonts/Montserrat/montserrat.css');

:root {
	--font-stack: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
}

body {
	font-family: var(--font-stack);
	font-weight: 300;

	min-height: 100vh;
	width: 100vw;

	font-size: 16px;
}

svg.icon {
	height: 24px;
	width: 24px;

	vertical-align: baseline;
}
</style>
