<template>
	<div class="home">
		<div class="home__container">
			<img src="/logo.svg"/>
			<div class="home__claim">
				Working hard. Stay tuned.
			</div>
		</div>
		<div class="menu">
			<a href="#" @click="popContact = true">contact<icon-mdi icon="mdiChevronRight" /></a><br>
			<a href="#" @click="popImprint = true">Impressum<icon-mdi icon="mdiChevronRight" /></a>
		</div>
		<popup v-if="popImprint" @close="popImprint = false">
			<imprint />
		</popup>
		<popup v-if="popContact" @close="popContact = false">
			<contact />
		</popup>
	</div>
</template>

<script lang="ts">
import {ref} from 'vue';
import {mdiChevronRight} from '@mdi/js';

import { registerIcons } from '@/Icons';

import contact from "@/components/contact.vue";
import imprint from "@/components/Imprint.vue";
import popup from "@/components/popup.vue";

registerIcons({mdiChevronRight});

export default {
	components: {
		contact,
		imprint,
		popup,
	},

	setup() {
		return {
			popImprint: ref(false),
			popContact: ref(false),
		}
	}
}
</script>

<style lang="scss">
.home {
	min-height: 100vh;
	width: 100%;
	height: -webkit-fill-available;;

	background-image: url("/img/tobias-van-schneider-230168-unsplash.jpg");
	background-size: cover;

	display: grid;
	grid-template-columns: 300px auto 300px;

	@media screen and (max-width: 1600px) {
		grid-template-columns: auto;
	}

	&__container {
		flex: 0 0;

		grid-column: 2 / 3;

		@media screen and (max-width: 1600px) {
			grid-column: 1 / -1;
		}

		margin-left: auto;
		margin-right: auto;

		height: fit-content;
		align-self: center;

		display: grid;
		align-items: center;
		justify-items: center;

		grid-row-gap: 96px;

		img {
			width: 90%;
		}
	}

	&__claim {
		width: 90%;
		font-size: 56px;
		white-space: nowrap;

		@media screen and (max-width: 1600px) {
			white-space: unset;
		}
	}
}

.menu {
	align-self: flex-end;
	justify-self: flex-end;
	padding: 32px;
	text-align: right;
	font-size: 32px;

	a {
		color: #000;
	}

	i {
		vertical-align: middle;
	}
}
</style>
