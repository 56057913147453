<template>
	<div class="popup__container" :class="{'popup--direct':direct}">
		<div class="popup__skirm" @click="$emit('close')" />
		<slot v-if="direct" class="popup__content" />
		<div v-else class="popup__content card">
			<div class="popup__close" @click="$emit('close')">
				<icon-mdi icon="mdiClose" />
			</div>
			<slot />
		</div>
	</div>
</template>

<script lang="ts">
import {mdiClose} from '@mdi/js';
import {registerIcons} from '@/Icons';

registerIcons({mdiClose});

export default {
	name: "Popup",
	props: {
		direct: {
			type: Boolean,
			default: false,
		}
	}
};
</script>

<style lang="scss">
.popup {
	&__skirm + div,
	&__content {
		background-color: #ffffff;

		position: fixed;
		top: 50vh;
		left: 50vw;
		transform: translate(-50%, -50%);
		z-index: 8;

		max-height: 90vh;
		max-width: 90vw;
		overflow: auto;
	}

	// &__content:not(&--direct) {
	// 	padding: 32px;
	// }

	&__close {
		width: 100%;
		text-align: right;
		cursor: pointer;
	}

	&__skirm {
		background-color: rgba(0, 0, 0, 0.3);
		position: fixed;
		top: -100vh;
		left: -100vw;
		right: -100vw;
		bottom: -100vh;
		z-index: 7;
	}

	&__container {
		position: fixed;
	}

}
</style>
